import { useState } from 'react'

interface FieldConfig {
    amenity: string
    required: boolean
    restrictions: string
    name: string
}

const oldRequired: FieldConfig[] = [
    {
        amenity: 'neighborhood',
        required: true,
        restrictions: ' ',
        name: 'vecindario',
    },
    {
        amenity: 'price',
        required: true,
        restrictions: '100000-999999999999',
        name: 'precio',
    },
    {
        amenity: 'administration',
        required: true,
        restrictions: '0-999999999999',
        name: 'administración',
    },
    {
        amenity: 'stratum',
        required: true,
        restrictions: '1-6',
        name: 'estrato',
    },
    {
        amenity: 'video',
        required: false,
        restrictions: ' ',
        name: 'video',
    },
    {
        amenity: 'description',
        required: true,
        restrictions: '[a-z0-9]{1,200}',
        name: 'descripción',
    },
]

const useDynamicForm = (formConfig: FieldConfig[]) => {
    const itemsPerPage = 20
    const [currentPage, setCurrentPage] = useState<number>(1)

    const formConfigLowercase: FieldConfig[] = formConfig.map((item: any) => ({
        amenity: item.Amenity,
        required: item.Required,
        restrictions: item.Restrictions,
        name: item.Name,
    }))

    const totalPages = Math.ceil(formConfigLowercase.length / itemsPerPage)
    const paginatedFields = Array.from({ length: totalPages }, (_, index) =>
        formConfigLowercase.slice(index * itemsPerPage, (index + 1) * itemsPerPage)
    )

    let requiredFields: FieldConfig[]
    //Agregar temporalmente campos faltantes
    if (currentPage === 1) {
        requiredFields = oldRequired
        const newRequired = paginatedFields[currentPage - 1].filter((field) => field.required)
        requiredFields = [...requiredFields, ...newRequired]
    } else {
        requiredFields = paginatedFields[currentPage - 1].filter((field) => field.required)
    }
    // Fin campos temporales*
    // const requiredFields = paginatedFields[currentPage - 1].filter((field) => field.required)
    const optionalFields = paginatedFields[currentPage - 1].filter((field) => !field.required)

    return { requiredFields, optionalFields, currentPage, setCurrentPage, totalPages }
}

export default useDynamicForm
